<template>
  <div class="v_layout_second">
    {{$route.meta.title}}
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss">
.v_layout_second {
  height: 100%;
  background: #fff;
}
</style>